<template>
  <div
    class="tab-pane fade"
    id="mail-setting"
    role="tabpanel"
    aria-labelledby="mail-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Mail Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-12 col-lg-4">
          <!-- Host -->
          <WeInput name="host" label="Sunucu Adresi" v-model="data.host" />
          <!-- Host -->
        </div>
        <div class="col-12 col-lg-4">
          <!-- Port -->
          <WeInput name="port" label="Port" v-model="data.port" />
          <!-- Port -->
        </div>
        <!-- Encrypt -->
        <div class="col-12 col-lg-4">
          <label for="encrypt" class="custom-label">Şifreleme</label>
          <select id="encrypt" class="custom-select" v-model="data.encrypt">
            <option value="tls">TLS</option>
            <option value="ssl">SSL</option>
          </select>
        </div>
        <!-- Encrypt -->
        <div class="col-12">
          <!-- Sender -->
          <WeInput
            name="email"
            type="email"
            label="Gönderen E-Posta Adresi"
            v-model="data.sender"
          />
          <!-- Sender -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Username -->
          <WeInput
            name="mail-username"
            label="Kullanıcı Adı"
            autocomplete="mail-username"
            v-model="data.username"
          />
          <!-- Username -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Password -->
          <WeInput
            name="mail-password"
            type="password"
            label="Şifre"
            autocomplete="new-password"
            v-model="data.password"
          />
          <!-- Password -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Mail",
  data() {
    return {
      data: {
        host: this.settings["mail.host"],
        port: this.settings["mail.port"],
        username: this.settings["mail.username"],
        password: this.settings["mail.password"],
        encrypt: this.settings["mail.encrypt"] || "tls",
        sender: this.settings["mail.sender"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      if (!this.validateForm()) {
        return;
      }
      this.settings["mail.host"] = this.data.host;
      this.settings["mail.port"] = this.data.port;
      this.settings["mail.username"] = this.data.username;
      this.settings["mail.password"] = this.data.password;
      this.settings["mail.encrypt"] = this.data.encrypt;
      this.settings["mail.sender"] = this.data.sender;

      this.$parent.$emit("on-save", this.settings);
    },
    validateForm() {
      let result = true;

      if (!this.data.host && this.data.password) {
        result = false;
        this.$toast.error("Mail Ayarları: Lütfen Sunucu Adresi Girin");
      }
      if (!this.data.username && this.data.password) {
        result = false;
        this.$toast.error("Mail Ayarları: Lütfen Kullanıcı Adı Girin");
      }

      return result;
    },
  },
  computed: {
    ...mapState(["shared"]),
  },
};
</script>
